body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.zone-regulations table {
  width: 100%;
  border-collapse: collapse;
}

.zone-regulations th, .zone-regulations td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.zone-regulations th {
  background-color: hsl(201, 32%, 90%);;
}

@media (max-width: 768px) {
  .zone-regulations table {
    font-size: 14px;
  }
}
/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Main Page Styling */
body {
  background-color: #f3f9fd; /* Light blue background */
  color: #333; /* Dark gray for text */
  font-family: 'Arial', sans-serif;
}

h1 {
  color: #0066cc; /* Medium blue for primary headings */
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2.5rem;
}

p {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
}

/* Button Styling */
button {
  display: block;
  margin: 0 auto 2rem;
  padding: 0.8rem 2rem;
  background-color: #0066cc; /* Medium blue */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #004a99; /* Darker blue */
}

/* Table Styling */
table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-top: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Table Cell Styling */
th, td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
  vertical-align: top; /* Aligns text to the top of each cell */
}

th {
  background-color: #0066cc;
  color: white;
  font-size: 1.1rem;
}

td {
  font-size: 1rem;
  color: #333;
}

tr:nth-child(even) {
  background-color: #e8f4fc;
}

/* Specific styling for the Size Limit column */
td:last-child {
  width: 20%; /* Adjust width if needed */
  white-space: normal; /* Allows text to wrap for long entries */
}


/* Table Header Centering */
table th {
  text-align: center;
}

/* Table Cell Centering */
table td {
  vertical-align: top;
}

/* Footer for Site Consistency */
footer {
  text-align: center;
  padding: 1rem;
  font-size: 0.9rem;
  color: #777;
  background-color: #f3f9fd;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
}


/* Heading Styling Above Table */
.table-heading {
  color: #004a99; /* Darker blue for heading */
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.tabs button {
  padding: 0.5rem 1.5rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.tabs button.active {
  background-color: #0066cc;
  color: white;
  font-weight: bold;
}

.tabs button:hover {
  background-color: #004a99;
  color: white;
}

.status.Open {
  color: green;
  font-weight: bold;
}

.status.Closed {
  color: gray;
  font-weight: bold;
}

.waterbody-item {
  display: flex;
  justify-content: space-between; /* This will separate the elements evenly */
}

.waterbody-name {
  margin-right: 20px; /* Adjust the value as needed for more spacing */
}

.waterbody-coordinates {
  flex-shrink: 0; /* Prevents the coordinates from shrinking */
}
