/* Tabs Container Styling */
.tabs-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent tabs from wrapping */
  padding: 10px;
  justify-content: center;
  margin-top: 10px;
  gap: 0.5rem; /* Space between tabs */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scroll-padding-right: 20px; /* Ensure there's padding at the end of scrolling */
}

.tab {
  flex: 0 0 auto; /* Prevent tabs from shrinking */
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.tab.active {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

/* Responsive Styles for Smaller Screens */
@media (max-width: 480px) {
  .tabs-container {
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .tab {
    padding: 8px 15px; /* Adjust padding for mobile */
    font-size: 0.9rem; /* Smaller font size */
    margin-right: 10px; /* Add margin to ensure the last tab is not cut off */
  }
}

.custom-regulations-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-top: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-regulations-table th, .custom-regulations-table td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}

.custom-regulations-table th {
  background-color: #0066cc;
  color: white;
  font-size: 1.1rem;
}

.custom-regulations-table td {
  font-size: 1rem;
  color: #333;
}

.custom-regulations-table tr:nth-child(even) {
  background-color: #e8f4fc;
}

/* General Mobile Adjustments */
@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: left;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f0f8ff; /* Light blue hover effect */
}
