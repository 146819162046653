/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Main Page Styling */
body {
  background-color: #f3f9fd; /* Light blue background */
  color: #333; /* Dark gray for text */
  font-family: 'Arial', sans-serif;
}

h1 {
  color: #0066cc; /* Medium blue for primary headings */
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2.5rem;
}

p {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
}

/* Button Styling */
button {
  display: block;
  margin: 0 auto 2rem;
  padding: 0.8rem 2rem;
  background-color: #0066cc; /* Medium blue */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #004a99; /* Darker blue */
  transform: translateY(-2px); /* Adds a lift effect on hover */
}

/* Map Container Styling */
.map-container {
  height: 50vh;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .map-container {
    height: 35vh; /* Reduced height for very small screens */
  }
}

/* Custom Regulations Table Styling */
.custom-regulations-table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-top: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-regulations-table th, .custom-regulations-table td {
  padding: 1rem;
  border: 1px solid #ccc; /* Adds subtle borders */
  text-align: left;
  vertical-align: top;
}

.custom-regulations-table th {
  background-color: #0066cc;
  color: white;
  font-size: 1.1rem;
  position: sticky; /* Sticky header */
  top: 0;
  z-index: 2;
  text-align: center;
}

.custom-regulations-table td {
  font-size: 1rem;
  color: #333;
}

.custom-regulations-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Softer background */
}

.custom-regulations-table tr:hover {
  background-color: #e0f7fa; /* Light cyan background */
}

/* Footer for Site Consistency */
footer {
  text-align: center;
  padding: 1rem;
  font-size: 0.9rem;
  color: #777;
  background-color: #f3f9fd;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background: linear-gradient(180deg, #f3f9fd, #e0f7fa); /* Light gradient */
}

/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.tabs button {
  padding: 0.5rem 1.5rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.tabs button.active {
  background-color: #0066cc;
  color: white;
  border-bottom: 2px solid #004a99; /* Adds a border to active tab */
  font-weight: bold;
}

.tabs button:hover {
  background-color: #004a99;
  color: white;
  transform: translateY(-2px); /* Adds a lift effect on hover */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .custom-regulations-table {
    font-size: 0.9rem;
    overflow-x: auto; /* Enable horizontal scrolling */
  }

  .custom-regulations-table th, .custom-regulations-table td {
    padding: 0.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.dropdown-menu:hover .dropdown-content {
  display: block;
  opacity: 1;
}

.dropdown-content a {
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

/* Make the tabs container scrollable */
.tabs-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.tabs-container .tab {
  flex: 0 0 auto;
  margin: 5px;
}

/* Accessibility: Focus State */
button:focus, .tabs button:focus {
  outline: 2px solid #004a99; /* Visible outline for accessibility */
}
/* CSS for Horizontal Scrolling on Mobile */
.table-wrapper {
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.custom-regulations-table {
  min-width: 600px; /* Ensures table does not shrink too much */
}


@media (max-width: 768px) {
  /* Hide unnecessary columns for mobile */
  .zone-wide .spot-daily-limit,
  .zone-wide .spot-size-limit,
  .zone-wide .con-daily-limit,
  .zone-wide .con-size-limit {
    display: none;
  }

  /* Adjust table layout for mobile */
  .zone-wide th,
  .zone-wide td {
    text-align: left; /* Adjust alignment for better readability */
  }

  .zone-wide th:nth-child(3),
  .zone-wide td:nth-child(3) {
    display: none; /* Example of column hiding by index */
  }
}
